(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
        	bannerSlideHeight = -1,
        	opinioneSlideHeight = -1,
        	categorySlideHeight = -1,
        	sportSlideHeight = -1,
        	coolSlideHeight = -1,
        	$cancelForm = $('.cancel_button'),
        	$aboutUsForm = $('.form_about_us'),
        	$contactButton = $('.contact_button'),
        	$contactDefault = $('.contact_default'),
            defaultEasing = [0.4, 0.0, 0.2, 1];

        //End Variables

        $('.material-icons').removeClass('transparent');
        
        // Navigation Toggle 

        $('.menu_toggle_icon_holder').click(function() {
        	$('body').toggleClass('navigation_toggle');
        });
        if( $('body').hasClass('navigation_toggle')) {
        	$('main').click(function() {
        		$('body').removeClass('navigation_toggle');
        	});
        }

        // End of Navigation Toggle

		// Mobile Per Ne Contact Toggle
        $contactButton.click(function() {
            $aboutUsForm.toggleClass('active');
            $contactDefault.toggleClass('in-active');
        });
        $cancelForm.click(function() {
            $aboutUsForm.toggleClass('active');
            $contactDefault.toggleClass('in-active');
        });

        // Sliders 

		$('.banner_slider').slick({
		  dots: true,
		  speed: 300,
		  arrows: false,
		  infinite: true,
		  slidesToShow: 1,
		  adaptiveHeight: false
		});

		$('.opinione_slider').slick({
		  dots: true,
		  speed: 300,
		  arrows: false,
		  infinite: true,
		  slidesToShow: 1,
		  adaptiveHeight: false,
		});

		$('.category_slider').slick({
		  dots: true,
		  speed: 300,
		  arrows: false,
		  infinite: true,
		  slidesToShow: 1,
		  adaptiveHeight: false
		});
		$('.sport_slider').slick({
		  dots: false,
          arrows: false,
		  infinite: true,
          slidesToShow: 1,
          centerMode: true,
		  responsive: [
		    {
		      breakpoint: 1366,
		      settings: {
		        centerPadding: '100px',
		      }
		    },
		    {
		      breakpoint: 768,
		      settings: {
		        centerPadding: '70px',
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        centerPadding: '40px',
		      }
		    }]
		}); 
		$('.cool_slider').slick({
		  dots: true,
		  speed: 300,
		  arrows: false,
		  infinite: true,
		  slidesToShow: 1,
		  adaptiveHeight: false
		});

		$('.partners_slider').slick({
		  dots: true,
		  speed: 300,
		  infinite: true,
		  adaptiveHeight: false,
		  responsive: [
			{
		      breakpoint: 1030,
		      settings: {
		        slidesToShow: 4,
		        slidesToScroll: 4
		      }
			},
			{
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
			},
		    ]
		});
		

		$('.js_tjera').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		  console.log('beforeChange', currentSlide, nextSlide);
		});
		$('.js_tjera').on('afterChange', function(event, slick, currentSlide){
		  console.log('afterChange', currentSlide);
		});
		$('.js_tjera').slick({
		  dots: false,
		  arrows: false,
		  infinite: false,
		  slidesToShow: 2.3,
		  centerMode: false,
		  edgeFriction: 0,
		  responsive: [
			{
		      breakpoint: 480,
		      settings: {
		        centerPadding: '30px',
		      }
		    }]
        });


		$('.most_watch_videos_items').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		  console.log('beforeChange', currentSlide, nextSlide+1);
		});
		$('.most_watch_videos_items').on('afterChange', function(event, slick, currentSlide){
		  console.log('afterChange', currentSlide);
		});
		$('.most_watch_videos_items').slick({
		  dots: false,
		  arrows: false,
		  infinite: false,
		  slidesToShow: 2.3,
		  centerMode: false,
		  responsive: [
			{
		      breakpoint: 480,
		      settings: {
		        centerPadding: '30px',
		      }
		    }]
        });
		

		
		// End of sliders

		// Zgjedhjet Slider Effects
		$('.partite_list li').click(function() {
			$('.partite_list li').removeClass('active');
			$('.partite_list li').removeClass('no_before');

			$(this).addClass('active');
			$(this).removeClass('no_before');
			$(this).next().addClass('no_before');
		});
        var getAll = jQuery('.all_tag');
        var getPDK = jQuery('.pdk_tag');
        var getLDK = jQuery('.ldk_tag');
        var getVV = jQuery('.vv_tag');

        function resetSliderHTML ( ) {
			var $limitedHeight = $('.zgjedhjet_slider').outerHeight();
            $('.zgjedhjet_slider').remove();
            $('.zgjContainer').append('<div class="zgjedhjet_slider"><ul class="slides loading_class"></ul></div>');
            $('.zgjedhjet_slider .slides').css('min-height',$limitedHeight);
        }
        function reinstantiateSlider ( ) {
			$('.zgjedhjet_slider').flexslider({
				animation: "slide",
				directionNav: true,
				prevText: "shko majtas",
    			nextText: "shko djathtas",
			  	animationLoop: false
			});
	   		$(".zgjedhjet_slider .slides").removeClass('loading_class');
        }
        function setNewSliderElements(elements) { 
            resetSliderHTML();
            $(".zgjedhjet_slider .slides").append(elements);
			setTimeout(function(){reinstantiateSlider();}, 100);
        }
        setNewSliderElements(getPDK);

        jQuery('body').on('click','#pdk_tag_parent',function(){
            setNewSliderElements(getPDK)
        });
        jQuery('body').on('click','#ldk_tag_parent',function(){
            setNewSliderElements(getLDK)
        });
        jQuery('body').on('click','#vv_tag_parent',function(){
            setNewSliderElements(getVV)
        });
        jQuery('body').on('click','#all_tag_parent',function(){
            setNewSliderElements(getAll);
        });
		// End of Zgjedhjet Slider Effects

		// Elements height 

		$('.banner_slider_text .item_title h2').each(function() {
			if($(this).height() > bannerSlideHeight ) {
				bannerSlideHeight = $(this).outerHeight();
			}
		$('.banner_slider_text .item_title h2').height(bannerSlideHeight);
		});
		
		$('.opinione_slider_text .item_title h2').each(function() {
			if ($(this).height() > opinioneSlideHeight) {
				opinioneSlideHeight = $(this).outerHeight();
			} 
		$('.opinione_slider_text .item_title h2').height(opinioneSlideHeight);
		});
		
		$('.category_slider_text .item_title h2').each(function() {
			if ($(this).height() > categorySlideHeight) {
				categorySlideHeight = $(this).outerHeight();
			} 
		$('.category_slider_text .item_title h2').height(categorySlideHeight);
		});
		
		$('.sport_slider .sport_article_text h3').each(function() {
			if($(this).height() > sportSlideHeight) {
				sportSlideHeight = $(this).outerHeight();
			}
		$('.sport_slider .sport_article_text h3').height(sportSlideHeight);
		});


		$('.cool_slider_item .cool_slider_title').each(function() {
			if($(this).outerHeight() > coolSlideHeight) {
				coolSlideHeight = $(this).outerHeight();
			}
		$('.cool_slider_item .cool_slider_title').css('min-height', coolSlideHeight);
		});


        $('.navigation').css('display', 'block');
		$('.banner_slider.loading_class').removeClass('loading_class');
		$('.opinione_slider.loading_class').removeClass('loading_class');
		$('.category_slider.loading_class').removeClass('loading_class');
		$('.sport_slider.loading_class').removeClass('loading_class');
		$('.cool_slider.loading_class').removeClass('loading_class');
		$('.slider_tab_holder.loading_class').removeClass('loading_class');

		// End of elements height 

		// Accordions
			$('#footer_accordion_1').click(function() {
				$(this).find('.footer_list_holder').toggle("slow");
			});
			$('#footer_accordion_2').click(function() {
				$(this).find('.footer_list_holder').toggle("slow");
			});
			$('#footer_accordion_3').click(function() {
				$(this).find('.footer_list_holder').toggle("slow");
			});
			$('#footer_accordion_4').click(function() {
				$(this).find('.footer_list_holder').toggle("slow");
			});
		// End of Accordions
    });
}(window.jQuery, window, document));
